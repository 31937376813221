iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.tablediv {
  display: flex;
  justify-content: center;
  align-items: center;
}
#transaction th #myicon :hover {
  cursor: pointer;
}
#transaction th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: purple;
  color: white;
}
#transaction {
  margin-top: 50px;
}
#transaction tr:nth-child(even) {
  background-color: #f2f2f2;
}
#transaction td,
#transaction th {
  border: 1px solid #ddd;
  padding: 8px;
}
.dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.confirm-dialog {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 1001;
}
#cancelbutton {
  background-color: white;

  border-color: white;
  margin-right: 10px;
}
#confirmbutton {
  background-color: #04aa6d;
  border-color: #04aa6d;
  margin-left: 10px;
}
#deletebuttons {
  display: flex;
}
